// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-contact-page-jsx": () => import("./../../../src/templates/ContactPage.jsx" /* webpackChunkName: "component---src-templates-contact-page-jsx" */),
  "component---src-templates-faq-page-jsx": () => import("./../../../src/templates/FaqPage.jsx" /* webpackChunkName: "component---src-templates-faq-page-jsx" */),
  "component---src-templates-home-page-jsx": () => import("./../../../src/templates/HomePage.jsx" /* webpackChunkName: "component---src-templates-home-page-jsx" */),
  "component---src-templates-not-found-page-jsx": () => import("./../../../src/templates/NotFoundPage.jsx" /* webpackChunkName: "component---src-templates-not-found-page-jsx" */),
  "component---src-templates-privacy-policy-page-jsx": () => import("./../../../src/templates/PrivacyPolicyPage.jsx" /* webpackChunkName: "component---src-templates-privacy-policy-page-jsx" */),
  "component---src-templates-resources-page-jsx": () => import("./../../../src/templates/ResourcesPage.jsx" /* webpackChunkName: "component---src-templates-resources-page-jsx" */),
  "component---src-templates-use-case-page-jsx": () => import("./../../../src/templates/UseCasePage.jsx" /* webpackChunkName: "component---src-templates-use-case-page-jsx" */)
}

